import dateHelper from '@/libs/datetime'
import calendarHelper from '../calendar/calendar'

/**
 * attendance mixin
 */
export default {
  data () {
    return {
      // 筛选年份、月份及天数
      filterYear: '',
      filterMonth: '',
      filterDays: 0,
      // 当前日期
      dateNow: new Date()
    }
  },
  methods: {
    /**
     * 格式化表头名称
     * @note “日期”表头
     * @param { Number } index 月份中的第几天
     */
    formatHeaderDateColumn (index) {
      return calendarHelper.formatDateName(index, this.filterMonth);
    },

    /**
     * Table 单元格 style
     * @param { Object } rowData 行数据
     * @param { Object } columnData 列数据
     * @param { Number } refer 引用源（1-门店排班，2-集团考勤）
     */
    getCellStyleWithRefer (rowData, columnData, refer) {
      if (/date_\d{1,2}/.test(columnData.property)) {
        let style = {};
        if (refer===1 && this.isScheduleEditable) {
          style['cursor'] = 'pointer';
        }
        let data = this.checkDateColumnData(rowData, Number(columnData.property.split('_')[1]));
        if (data && this.isAttendanceAbnormal(data.lateStatus, data.leaveEarlyStatus)) {
          let markData = this.attendanceAbnormalMark(data.lateStatus, data.leaveEarlyStatus);
          style['background-color'] = markData.color;
        }
        return style;
      }
    },


    /** 数据缓存处理 **/
    /**
     * set筛选月份/天数
     * @param { Date } date 日期对象
     */
    setFilterDays (date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      this.filterYear = year;
      this.filterMonth = month;
      this.filterDays = dateHelper.daysOfMonth(year, month);
    },

    /**
     * 检查并处理考勤数据
     * @note 检查表格渲染时循环、查找次数处理为1次
     * - 数据作内容缓存
     * - 第一次处理缓存不存在，先处理缓存数据，再返回数据
     * - 第二次及之后处理缓存存在，返回缓存数据
     * - 不存在返回 null
     * @param { Object } data row 数据
     * @param { Number } index 月度下第几天
     * @returns { Object|Null } 考勤数据
     */
    checkDateColumnData (data, index) {
      if (this.dailyDatas && this.dailyDatas[data.id]) {
        return this.getDateColumnData(data.id, index);
      } else {
        if (data.details.length) {
          let dailyDatas = this.dailyDatas || {};
          let tmpData = {};
          data.details.forEach(item => {
            let day = Number(item.workShiftDate.split('-')[1]);
            tmpData[day] = item;
          });
          dailyDatas[data.id] = tmpData;
          this.dailyDatas = dailyDatas;
          return tmpData[index];
        } else {
          return null;
        }
      }
    },

    /**
     * 获取考勤数据
     * @param { Number } id 数据行 id
     * @param { Number } index 月度下第几天
     * @return { Object|Null } 考勤数据
     */
    getDateColumnData (id, index) {
      if (this.dailyDatas) {
        let dailyData = this.dailyDatas[id];
        return dailyData&&dailyData[index] ? dailyData[index] : null;
      }
      return null;
    },

    /**
     * 获取考勤描述
     * @param { Number } id 数据行 id
     * @param { Number } index 月度下第几天
     * @return { String } 考勤描述
     */
    getDateAttendanceDesc (id, index) {
      let data = this.getDateColumnData(id, index);
      return data.workShiftName;
    }
  }
}
