/**
 * Table with filter 视图 mixin
 */
export default {
  data () {
    return {
      // Table 配置
      noDataText: '',
      tableMaxHeight: null,
      tableDatas: [],

      // 分页
      pageData: {
        pageSize: 15,
        currentPage: 1,
        total: 0,
        pageCount: 0
      },


      // filter end form item label-width 配置
      endLabelWidth: undefined,
    }
  },
  methods: {
    /**
     * 处理 label-width，查询条件如果一行呈现，设置事件按钮左间距
     * @param { String } refForm Filter form 组件引用信息
     */
    setFilterLabelWidth (refForm) {
      this.$nextTick(() => {
        const refEL = this.$refs[refForm];
        if (refEL) {
          const filterFormNodes = refEL && refEL.$el;
          const formCH = filterFormNodes.clientHeight;
          const colCH = filterFormNodes.children && filterFormNodes.children[0].children && filterFormNodes.children[0].children[0].clientHeight;
          if (formCH == colCH) {
            this.endLabelWidth = 0;
          } else {
            this.endLabelWidth = undefined;
          }
        }
      });
    },

    /**
     * 计算 table 最大高度
     * @param { Number } margin 额外的 margin 值
     * @param { String } refBox 容器视图引用信息
     * @param { String } subRefs 容器视图下子视图引用信息，多个可以多参数形式传递
     */
    setTableMaxHeight (margin=0, refBox, ...subRefs) {
      const refBoxEL = this.$refs[refBox];
      let subRefELs = [];
      if (arguments.length > 2) {
        subRefELs = Array.from(arguments).slice(2).map(item => {
          return this.$refs[item];
        });
      }
      if (!refBoxEL || (subRefELs.length>0 && !subRefELs[0])) {
        this.tableMaxHeight = 101;
        return;
      }
      const subELHeight = subRefELs.map(item => item.clientHeight)
                                   .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      this.tableMaxHeight = refBoxEL.clientHeight - subELHeight - margin;
    },
  }
}
