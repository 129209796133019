/**
 * 获取日期对象(兼容 Safari 不能正确由'2020-07-22 12:12:12'生成Date对象问题)
 * @param  { String | Number | Date } date 日期
 * @returns { Date } Date 对象
 */
function getDate (date) {
  if (typeof date === 'string') {
    date = date.replace(/\-/ig, '/');
  }
  return new Date(date);
}

/**
 * 格式化日期
 * @params { String | Number | Date } date 待格式化的日期
 * @params { String } 格式(如: yyyy-MM-dd HH:mm:ss.S)
 *        - 年：yyyy、yy
 *        - 月：MM、M
 *        - 日：dd、d
 *        - 时：HH、H
 *        - 分：mm、m
 *        - 秒：ss、s
 *        - 毫秒：S
 * @returns { String } 格式化后的日期
 */
function format (date, pattern='yyyy-MM-dd') {
  if (!date) {
    return '';
  }
  let dateTmp = date;
  if (!(dateTmp instanceof Date)) {
    dateTmp = getDate(date);
    if (dateTmp.toString() === 'Invalid Date') {
      return 'Invalid Date';
    }
  }
  const o = {
    'M+': dateTmp.getMonth() + 1,
    'd+': dateTmp.getDate(),
    'H+': dateTmp.getHours(),
    'm+': dateTmp.getMinutes(),
    's+': dateTmp.getSeconds(),
    'S': dateTmp.getMilliseconds()
  };
  // 通过正则匹配与替换完成格式化
  let matched = pattern.match(/y+/);
  if (matched) {
    pattern = pattern.replace(matched[0], (dateTmp.getFullYear()+'').substring(4 - matched[0].length));
  }
  for (const k in o) {
    matched = pattern.match(new RegExp(k));
    if (matched) {
      pattern = pattern.replace(matched[0], (matched[0].length === 1) ? (o[k]) : (('00' + o[k]).substring(('' + o[k]).length)));
    }
  }
  return pattern;
}

/**
 * 格式化日期时间区间，结束日期时间调整为 23:59:59，起始日期调整为 00:00:00
 * @param { Array } range 日期时间区间
 * @returns { Array } 格式化后的日期区间
 */
function setTime2End (range) {
  const rangeTmp = [ ...range ];
  if (rangeTmp && rangeTmp[0] && rangeTmp[1]) {
    let date = (rangeTmp[0] instanceof Date) ? rangeTmp[0] : this.getDate(rangeTmp[0]);
    rangeTmp[0] = new Date(date.toLocaleDateString());
    date = (rangeTmp[1] instanceof Date) ? rangeTmp[1] : this.getDate(rangeTmp[1]);
    rangeTmp[1] = new Date(new Date(date.toLocaleDateString()).getTime() + 86400000 - 1000);
  }
  return rangeTmp;
}

/**
 * 计算两个日期相差的天数
 * @param { Date } startDate 起始日期
 * @param { Date } endDate 截止日期
 * @param { Boolean } incEnd 是否包含截止日期（如：2016-12-13到2016-12-15，不包含相差2天，包含相差3天）
 * @returns { Number } 相差的天数
 */
function diffDay (startDate, endDate, incEnd=false) {
  // 把相差的毫秒数转换为天数
  const count = parseInt((endDate - startDate) / 1000 / 60 / 60 / 24);
  return incEnd ? count+1 : count;
}

/**
 * 在指定日期上增加天
 * @param { String | Number | Date } 待处理日期
 * @param { Number } days 要增加的天数
 * @returns { Date } 新日期
 */
function addDays (date, days) {
  let dateTmp = getDate(date);
  try {
    dateTmp.setDate(dateTmp.getDate() + days);
  } catch (exception) {
    console.error(exception.message);
  }
  return dateTmp;
}

/**
 * 获取月份下的天数
 * @param { Number } year 年
 * @param { Number } month 月（从 1 开始计数）
 * @returns { Number } 天数
 */
function daysOfMonth (year, month) {
  return new Date(year, month, 0).getDate();
}


/**
 * 公共日期时间处理
 */
export default {
  getDate,
  format,
  setTime2End,
  diffDay,
  addDays,
  daysOfMonth
}

