

/**
 * 格式化日期名称
 * @param { Number } day 天
 * @param { Number } month 月
 */
function formatDateName (day, month) {
  let name = '';
  if (month < 10) {
    name += '0' + month;
  } else {
    name += month;
  }
  name += '/';
  if (day < 10) {
    name += '0' + day;
  } else {
    name += day;
  }
  return name;
}

/**
 * 当月第一天在所在周中的第几天
 * @param { Date } date 指定日期
 * @returns { Number } 所在周第几天
 */
function firstDayOffsetOfWeek (date) {
  let firstDate = new Date(date);
  firstDate.setDate(1);
  return firstDate.getDay();
}

/**
 * 当月最后一天在所在周中的第几天
 * @param { Date } date 指定日期
 * @returns { Number } 所在周第几天
 */
function lastDayOffsetOfWeek (date) {
  let lastDate = new Date(date.getFullYear(), date.getMonth()+1, 0);
  return lastDate.getDay();
}


export default {
  formatDateName,
  firstDayOffsetOfWeek,
  lastDayOffsetOfWeek
}
