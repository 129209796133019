var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box-view" },
    _vm._l(_vm.datas, function(item, index) {
      return _c("div", { key: index, staticClass: "lump-item" }, [
        _c("div", {
          staticClass: "cube",
          style: { "background-color": item.color }
        }),
        _c("label", [_vm._v(_vm._s(item.title))])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }