import config from '@/config'
import Ajax from '@/libs/ajax'

const ajax = new Ajax();

// 获取品牌酒店列表（权限内）
let brandHotelList = ajax.post(config.apiConf.serviceApi + 'hotel/listBrandTree');
// 获取成员下拉列表
let memberSelectList = ajax.post(config.apiConf.serviceApi + 'userInfo/listUser');
// 检查历史考勤是否可编辑（是否已过考勤周期）
let checkHistoryEditable = ajax.post(config.apiConf.serviceApi + 'workshift/checkDate');

// 获取门店排班列表
let hotelScheduleList = ajax.post(config.apiConf.serviceApi + 'workshift/listWorkShift');
// 提交数据
let submitData = ajax.post(config.apiConf.serviceApi + 'workshift/submitAttendance');
// 撤销提交
let revokeSubmit = ajax.post(config.apiConf.serviceApi + 'workshift/withdraw');
// 删除数据
let deleteData = ajax.post(config.apiConf.serviceApi + 'workshift/delete');

// 获取门店排班详情
let getScheduleDetail = ajax.post(config.apiConf.serviceApi + 'workshift/getById');
// 保存门店排班信息
let saveHotelSchedule = ajax.post(config.apiConf.serviceApi + 'workshift/save');


// 获取集团考勤列表（分页）
let attendanceListPage = ajax.post(config.apiConf.serviceApi + 'attendance/pageAttendance');


export default {
  brandHotelList,
  memberSelectList,
  checkHistoryEditable,
  hotelScheduleList,
  submitData,
  revokeSubmit,
  deleteData,
  getScheduleDetail,
  saveHotelSchedule,

  attendanceListPage
}
